import React, { useContext, useState } from 'react';

//HOOKS
import { useSnackbar } from 'notistack';

//CSS
import { Button, TextField, Snackbar } from '@material-ui/core';

import styles from './index.module.scss';
import stylesBejelentkezes from './bejelentkezes.module.scss';

//HOC
import BasicLayout from '../layouts/basic_layout';
import BreadcrumbsLayout from '../layouts/breadcrumbs_layout';

//COMPONENTS
import Head from '../components/head';
import ErrorSnackbar from '../components/ErrorSnackbar/ErrorSnackbar';

//GATSBY
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
/**
 * how to programatically navigate
 * https://github.com/gatsbyjs/gatsby/issues/12537
 * https://spectrum.chat/gatsby-js/general/navigate-in-gatsby-simple-auth-example-leads-to-build-error~7b64a5ce-7de6-44eb-a343-de7b92203961
 *  */

// sneaky @reach/router from under the hood for the rescue
// import { navigate } from '@reach/router';

import gql from 'graphql-tag';
import { useMutation, useReactiveVar } from '@apollo/client';
import { accessTokenVar } from '../apollo/localState/accessTokenVar';
import { Alert } from '@material-ui/lab';
import { SystemStatusContext } from '../providers/SystemStatusProvider';

const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      accessToken
    }
  }
`;

const Bejelentkezes = props => {
  const { maintenance } = useContext(SystemStatusContext);

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { title: { eq: "Bejelentkezés" } }) {
        frontmatter {
          title
          mainpage
        }
        html
      }
    }
  `);

  const { enqueueSnackbar } = useSnackbar();

  const [loginUser] = useMutation(LOGIN_USER);

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    email: {
      touched: false,
    },
    password: {
      touched: false,
    },
    serverValidateError: null,
  });

  const [openError, setOpenError] = useState(false);

  const [displayInfoMessage, setDisplayInfoMessage] = useState(false);

  const { accessToken } = useReactiveVar(accessTokenVar);

  const inputChangedHandler = e => {
    setCredentials({ ...credentials, [e.target.id]: e.target.value });
  };

  const closeErrorSnackbarHandler = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  if (accessToken) {
    navigate('/');
    return null;
  }

  const formSubmittedHandler = async e => {
    e.preventDefault();
    setErrors({ ...errors, serverValidateError: null });
    try {
      const res = await loginUser({
        variables: {
          email: credentials.email,
          password: credentials.password,
        },
      });

      sessionStorage.setItem('accessToken', res.data.login.accessToken);
      accessTokenVar({
        accessToken: res.data.login.accessToken,
      });

      if (props?.location?.state?.navigateBack) {
        navigate(-1);
      } else {
        navigate('/');
      }
      enqueueSnackbar('Sikeres bejelentkezés!', {
        variant: 'success',
      });
    } catch (err) {
      const errorCode = err?.graphQLErrors?.[0]?.extensions?.code || '';
      const codeString = errorCode?.split('-')?.[1] || '';

      if (codeString) {
        try {
          const code = parseInt(codeString);

          if ((code - 2000) % 3 === 0) {
            setDisplayInfoMessage(true);
          } else {
            setDisplayInfoMessage(false);
          }
        } catch (e) {}
      }

      if (err.graphQLErrors[0]) {
        if (
          // err.graphQLErrors[0].extensions.code === 'BAD_USER_INPUT' &&
          err.message.includes('Probably bad credentials')
        ) {
          setErrors({
            ...errors,
            serverValidateError: 'A megadott adatok nem megfelelőek.',
          });
        } else if (
          err.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' &&
          err.message.includes('internal server error')
        ) {
          setErrors({
            ...errors,
            serverValidateError:
              'Váratlan hiba történt, kérjük próbálja meg később a belépést.',
          });
        } else if (
          err.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' &&
          err.message.includes('Too many requests')
        ) {
          setErrors({
            ...errors,
            serverValidateError:
              'Ön elérte az egy percen belül indítható maximális bejelentkezési kísérlet számát, kérjük próbálkozzon újra egy perc múlva.',
          });
        }
      } else {
        setErrors({ ...errors, serverValidateError: 'Hálózati hiba.' });
      }
      setOpenError(true);
    }
  };

  const inputFields = {
    email: {
      name: 'Olvasójegy // Email', // Külön kérés volt, nem véletlenül van így
      id: 'email',
      type: 'text',
    },
    password: {
      name: 'Jelszó',
      id: 'password',
      type: 'password',
    },
  };

  const inputFieldsDisplay = Object.keys(inputFields).map(inputField => {
    let error = null;
    if (errors[inputField].touched && !credentials[inputField])
      error = (
        <div style={{ color: 'red' }}>
          {inputFields[inputField].name} mezőt kötelező kitölteni!
        </div>
      );

    return (
      <React.Fragment key={inputField}>
        <label htmlFor={inputFields[inputField].id}>
          {inputFields[inputField].name}
        </label>
        <TextField
          variant="outlined"
          // label={inputFields[inputField].name}
          type={inputFields[inputField].type}
          id={inputFields[inputField].id}
          // placeholder={inputFields[inputField].name}
          value={credentials[inputField]}
          onChange={inputChangedHandler}
          fullWidth={true}
          error={credentials[inputField] ? false : errors[inputField].touched}
          onBlur={() =>
            setErrors({
              ...errors,
              [inputField]: {
                touched: true,
              },
            })
          }
        />
        {error}
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      <BasicLayout>
        <Head title={data.markdownRemark.frontmatter.title} />
        <BreadcrumbsLayout
          menuItems={[
            { name: data.markdownRemark.frontmatter.mainpage, path: '/' },
            { name: 'Bejelentkezés' },
          ]}
        >
          <div className={styles.textContentContainer}>
            <h3>Bejelentkezés</h3>
            {/* <div
              className={stylesBejelentkezes.mdContainer}
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            ></div> */}
            {displayInfoMessage && (
              <div className={stylesBejelentkezes.container}>
                <Alert severity="info">
                  Kedves Olvasónk!
                  <br />
                  <br />
                  Örömmel tájékoztatjuk, hogy online katalógusunk megújult.
                  Kérjük, hogy bejelentkezés előtt változtassa meg jelszavát a
                  Jelszó módosítása gomb megnyomásával. Az itt megadott e-mail
                  címre automatikusan elküldött link segítségével tudja a
                  módosítást végrehajtani az alábbiak figyelembevételével: a
                  jelszó legalább 8 karakter hosszúságú legyen, tartalmazzon
                  legalább egy számot, kis- és egy nagybetűt, valamint egy
                  speciális karaktert is.
                  <br />
                  <br />
                  Kellemes böngészést kívánunk!
                  <br />
                  <br />
                  KSH Könyvtár
                </Alert>
              </div>
            )}
            <div className={stylesBejelentkezes.container}>
              <form onSubmit={formSubmittedHandler}>
                {inputFieldsDisplay}
                <div className={stylesBejelentkezes.submitButtonContainer}>
                  <Button
                    type="submit"
                    className={stylesBejelentkezes.submitButton}
                    disabled={
                      maintenance ||
                      credentials.email.trim() === '' ||
                      credentials.password.trim() === ''
                    }
                  >
                    {data.markdownRemark.frontmatter.title}
                  </Button>
                  <Link to="/forgotten-password">Jelszó módosítása</Link>
                </div>
              </form>
            </div>
          </div>
        </BreadcrumbsLayout>
      </BasicLayout>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openError}
        autoHideDuration={6000}
        onClose={closeErrorSnackbarHandler}
      >
        <ErrorSnackbar
          onClose={closeErrorSnackbarHandler}
          variant="error"
          message={errors.serverValidateError}
        />
      </Snackbar>
    </React.Fragment>
  );
};
export default Bejelentkezes;
